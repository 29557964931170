<template>
  <page-header-wrapper @back="back">
    <template v-slot:title>
      {{ formTitle }}
    </template>
    <a-card :bordered="false">
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 2}" :wrapper-col="{ span: 20 }">
            <a-form-model-item label="Message Type" prop="noticeType" v-if="isView == 0">
              <a-row type="flex" justify="start" :gutter="4">
                <a-col :span="4">
                  <a-select placeholder="" v-model="form.sendType" @change="changeSentType">
                    <a-select-option :key="1">Clients</a-select-option>
                    <a-select-option :key="2">Users</a-select-option>
                    <a-select-option :key="3">Clients & Users</a-select-option>
                  </a-select>
                </a-col>
                <a-col :span="20">
                  <a-select
                    placeholder="All CLients/All User"
                    style="width: 100%"
                    mode="multiple"
                    showSearch
                    v-model="form.users"
                    optionFilterProp="title"
                  >
                    <a-select-option v-for="item in userListData" :key="item.id" :value="item.id" :title="item.nickname + (item.identity === 0 ? '(User)' : '(CLient)')">
                      {{ item.email }}{{item.identity === 0 ? '(User)' : '(CLient)'}}
                    </a-select-option>
                  </a-select>
                </a-col>
              </a-row>
            </a-form-model-item>
<!--            <a-form-model-item label="Message Status" prop="status" >-->
<!--              <a-radio-group v-model="form.status">-->
<!--                <a-radio :value="1">-->
<!--                  Enable-->
<!--                </a-radio>-->
<!--                <a-radio :value="0">-->
<!--                  Disable-->
<!--                </a-radio>-->
<!--              </a-radio-group>-->
<!--            </a-form-model-item>-->
          <a-form-model-item label="Message Title:" prop="noticeTitle">
            <a-input size="large" width="200px" :maxLength="200" v-model="form.noticeTitle" placeholder="" />
            <div style="display: flex;justify-content: flex-end">{{ ((form.noticeTitle && form.noticeTitle.length) || 0) }}/200</div>
          </a-form-model-item>
          <a-form-model-item label="Message Content:" prop="noticeContent">
            <editor v-model="form.noticeContent" />
          </a-form-model-item>
          <a-form-model-item label="Message Status" prop="status" v-if="isView == 0">
            <a-radio-group v-model="form.status">
              <a-radio :value="1">
                Enable
              </a-radio>
              <a-radio :value="0">
                Disable
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-form-model>
        <a-space style="margin-left: 8.5%" v-if="isView == 0">
          <a-button type="primary" :loading="submitLoading" :disabled="submitLoading" @click="submitForm">
            Save
          </a-button>
<!--          <a-button type="dashed" @click="back">-->
<!--            Cancel-->
<!--          </a-button>-->
        </a-space>
    </a-card>
  </page-header-wrapper>
</template>

<script>

import { getNotice, addNotice, updateNotice } from '@/api/system/notice'
import Editor from '@/components/Editor'
import bus from '@/utils/bus'
import events from '@/components/MultiTab/events'
import {allListUser} from "@/api/biz/user";

export default {
  name: 'NoticeForm',
  components: {
    Editor
  },
  dicts: ['sys_notice_status', 'sys_notice_type'],
  data() {
    return {
      wrapperCol: { span: 48 },
      submitLoading: false,
      total: 0,
      id: undefined,
      formTitle: '',
      // 表单参数
      form: {
        id: undefined,
        noticeTitle: undefined,
        noticeType: 2,
        noticeContent: '',
        status: 1,
        bstatus: 1,
        users: [],
        sendType: 3
      },
      rules: {
        noticeTitle: [{ required: true, message: 'Message Title cannot be empty', trigger: 'blur' }]
      },
      open: false,
      userListData:[],
      isView:0
    }
  },
  filters: {
  },
  created() {
    this.getAllUserList(null);
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    console.log('this.$route')
    console.log(this.$route)
    this.id = this.$route.params.id
    this.formTitle = this.$route.params.formTitle
    this.isView = this.$route.params.isView;
    if (this.id) {
      this.handleUpdate(this.id)
    } else {
      this.handleAdd()
    }
  },
  methods: {
    changeSentType(e) {
      console.log(e);
      if(e === 1) {
        this.getAllUserList(2);
      } else if(e === 2) {
        this.getAllUserList(1);
      } else if(e === 3) {
        this.getAllUserList(null);
      }
    },
    getAllUserList(identity) {
      allListUser({status:1,identity:identity}).then(res => {
        this.userListData = res.data;
      })
    },
    changeStatus(e) {
      let status = e.target.value
      this.form.status = status === '0' ? '1' : '0'
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        noticeTitle: undefined,
        noticeType: 2,
        noticeContent: '',
        status: 1,
        bstatus: 1,
        users: [],
        sendType : 3
      }
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.formTitle = 'Create Message'
    },
    /** 修改按钮操作 */
    handleUpdate(id) {
      this.reset()
      const noticeId = id
      getNotice(noticeId).then(response => {
        let str = response.data.status
        this.$set(response.data, 'bstatus', str)
        this.form = response.data;
        if(this.form.users) {
          this.form.users= this.form.users.join(',')
        } else {
          this.form.users= []
        }
        if(this.isView == 1) {
          this.formTitle = 'Message Detail'
        } else {
          this.formTitle = 'Modify Message'
        }
      })
    },
    handleUpdateData(id) {
      this.reset()
      const noticeId = id
      getNotice(noticeId).then(response => {
        let str = response.data.status
        this.$set(response.data, 'bstatus', str)
        this.form = response.data
        this.formTitle = 'Create or Modify Message'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      console.log(this.form.users)
      if(this.form.users.length > 0) {
        this.form.users = this.form.users.join(',')
      } else {
        this.form.users = null
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          // this.submitLoading = true
          if (this.form.id !== undefined) {
            updateNotice(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              // 去更新列表页的数据
              bus.$emit('updateNoticeList')
              this.back()
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addNotice(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              bus.$emit('updateNoticeList')
              this.back()
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    back() {
      let pages = this.$store.pages;
      pages.indexOf(this.$route)
      events.$emit('close', pages.indexOf(this.$route))
      this.$router.push('/systemSettins/notice')
    },
    onClose() {
      this.open = false
    },
    handleSubmit() {
      this.$refs.baseForm.validate(valid => {
        if (valid) {
          this.open = true
        } else {
          return false
        }
      })
    },
    //关闭当前标签页

  }
}
</script>
